import { AppInfo } from '../src/browser/AppInfo/AppInfo.js';
import { ELKFormatter } from '../src/browser/Formatter/ELKFormatter.js';
import { ConsoleTransport } from '../src/browser/Transport/ConsoleTransport.js';
import { HTTPTransport } from '../src/browser/Transport/HTTPTransport.js';
import { ErrorFilter } from '../src/shared/Filter/ErrorFilter.js';
import { PrettyFormatter } from '../src/shared/Formatter/PrettyFormatter.js';
import { SystemLogger } from '../src/shared/Logger/index.js';

import type { IBaseAppELKErrorLog, IBaseAppELKMessageLog, IFormatter, ITransport } from '../src/shared/index.js';

declare global {
  interface Window {
    _cianGlobalSystemLogger?: {
      application: string;
      filters?: string[];
    };
  }
}

const HTTP_TRANSPORT_ENV_TYPES = ['dev', 'beta', 'prod'];

export function createSystemLogger() {
  const application = window._cianGlobalSystemLogger?.application;
  const errorFilter = new ErrorFilter(
    (window._cianGlobalSystemLogger?.filters || []).map(regexString => new RegExp(regexString)),
  );

  const appInfo = new AppInfo(application);
  const { mcsRunEnvType } = appInfo;

  let formatter: IFormatter<IBaseAppELKErrorLog | IBaseAppELKMessageLog, string>;
  let transport: ITransport<IBaseAppELKErrorLog | IBaseAppELKMessageLog>;

  if (HTTP_TRANSPORT_ENV_TYPES.includes(mcsRunEnvType)) {
    formatter = new ELKFormatter(appInfo);
    transport = new HTTPTransport(formatter, appInfo.logBaseUrl);
  } else {
    formatter = new PrettyFormatter();
    transport = new ConsoleTransport(formatter);
  }

  return new SystemLogger({ transport, errorFilter });
}
