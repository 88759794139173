import { type TELKExceptionData } from './IELKAppLog.js';
import { type TAvailableLogJson, type TLogMeta, type TAvailableLogTypes } from './ILog.js';

export function convertMetaToKVCollection(meta: TLogMeta): TELKExceptionData[] {
  const collection: TELKExceptionData[] = [];

  for (const [key, value] of Object.entries(meta)) {
    if (!isValidValueType(value)) {
      if (process.env.NODE_ENV !== 'production' && process.env.NODE_ENV !== 'test') {
        console.warn(
          'Ошибка! Не допустимый тип значения у мета информации.\n' +
            `Ключ: "${key}", тип значения: "${typeof value}"\n` +
            'Данное поле будет исключено из лога и не попадет в ELK.',
        );
      }

      continue;
    }

    if (value !== null && !Array.isArray(value) && typeof value === 'object') {
      collection.push(...convertRecordToKVCollection(value, key));
    } else {
      if (Array.isArray(value)) {
        let arrIsInvalid = false;

        value.forEach(val => {
          if (!isValidValueType(val)) {
            arrIsInvalid = true;

            if (process.env.NODE_ENV !== 'production' && process.env.NODE_ENV !== 'test') {
              console.warn(
                'Ошибка! Не допустимый тип значения у мета информации.\n' +
                  `Ключ: "${key}", тип значения: "${typeof val}"\n` +
                  'Данное поле будет исключено из лога и не попадет в ELK.',
              );
            }
          }
        });

        if (arrIsInvalid) {
          continue;
        }
      }

      collection.push({ key, value });
    }
  }

  return collection;
}

export function convertRecordToKVCollection(record: TAvailableLogJson, preffix: string): TELKExceptionData[] {
  const collection: TELKExceptionData[] = [];

  for (const [partOfKey, value] of Object.entries(record)) {
    const key = `${preffix}.${partOfKey}`;

    if (!isValidValueType(value)) {
      if (process.env.NODE_ENV !== 'prodction' && process.env.NODE_ENV !== 'test') {
        console.warn(
          'Ошибка! Не допустимый тип значения у мета информации.\n' +
            `Ключ: "${key}", тип значения: "${typeof value}"\n` +
            'Данное поле будет исключено из лога и не попадет в ELK.',
        );
      }

      continue;
    }

    if (value !== null && !Array.isArray(value) && typeof value === 'object') {
      if (process.env.NODE_ENV !== 'prodction' && process.env.NODE_ENV !== 'test') {
        console.warn(
          'Ошибка! Типы значений вложенного json-объекта должны быть примитивами или массивом примитивов\n' +
            `Ключ: "${key}", тип значения: "${typeof value}"\n` +
            'Данное поле будет исключено из лога и не попадет в ELK.',
        );
      }

      continue;
    } else {
      collection.push({ key, value });
    }
  }

  return collection;
}

function isValidValueType(value: unknown): value is TAvailableLogTypes | TAvailableLogTypes[] | TAvailableLogJson {
  switch (typeof value) {
    case 'bigint':
    case 'boolean':
    case 'number':
    case 'object':
    case 'string':
    case 'undefined':
      return true;
    default:
      return false;
  }
}
