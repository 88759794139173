import { type GenericError, UnknownError } from '@cian/peperrors/shared';

import { createELKErrorLog } from '../Log/ELKLogs.js';

import type { IFilter } from '../Filter/IFilter.js';
import type { IBaseAppELKLog } from '../Log/IELKAppLog.js';
import type { ITransport } from '../Transport/ITransport.js';
import type { TError } from '../types.js';

interface IParams {
  transport: ITransport;
  errorFilter?: IFilter<TError>;
}

export class SystemLogger {
  private _transport: ITransport;
  private errorFilter: IFilter<TError> | undefined;

  public constructor(params: IParams) {
    this._transport = params.transport;
    this.errorFilter = params.errorFilter;
  }

  public setTransport(transport: ITransport) {
    this._transport = transport;
  }

  public log(log: IBaseAppELKLog) {
    this._transport.write(log);
  }

  public error(error: string | GenericError | Error) {
    if (this.errorFilter && !this.errorFilter.approve(error)) {
      return;
    }

    const message =
      typeof error === 'string'
        ? new UnknownError({
            message: error,
            domain: 'system-logger.SystemLogger.error.unknown-domain',
          })
        : error;

    this._transport.write(
      createELKErrorLog({
        message,
        level: 'error',
      }),
    );
  }

  public fatal(error: string | GenericError | Error) {
    const message =
      typeof error === 'string'
        ? new UnknownError({
            message: error,
            domain: 'system-logger.SystemLogger.fatal.unknown-domain',
          })
        : error;

    this._transport.write(
      createELKErrorLog({
        message,
        level: 'fatal',
      }),
    );
  }
}
