import { type IAppInfo } from './IAppInfo.js';
import { getConfigValue } from './getConfigValue/index.js';

declare global {
  interface Window {
    _cianSystemLogger: {
      [key: string]: { logBaseUrl: string };
    };
  }
}

const DEFAULT_LOG_BASE_URL = 'https://www.cian.ru/ajax/log-error/';

export class AppInfo implements IAppInfo {
  public application: string;
  public mcsRunEnv: string;
  public mcsRunEnvType: string;
  public version: string;

  public constructor(application: string | undefined) {
    this.application = application || 'unknown';

    this.init();
  }

  private init() {
    this.refresh();

    document.addEventListener('DOMContentLoaded', () => {
      this.refresh();
    });
  }

  private refresh() {
    this.mcsRunEnv = this.ensureMcsRunEnv();
    this.mcsRunEnvType = this.ensureMcsRunEnvType();
    this.version = this.ensureVersion();
  }

  public get href() {
    return window.location.href;
  }

  public get screenSize() {
    const { width, height } = window.screen;

    return `${width}/${height}`;
  }

  public get availableScreenSize() {
    const { availWidth, availHeight } = window.screen;

    return `${availWidth}/${availHeight}`;
  }

  public get logBaseUrl() {
    /**
     * Данный fallback необходим, так как есть сервисы, которые не используют @cian/web,
     * а разработчик может забыть установить переменную
     */
    if (!window._cianSystemLogger) {
      return DEFAULT_LOG_BASE_URL;
    }

    return (
      window._cianSystemLogger[this.application]?.logBaseUrl ||
      window._cianSystemLogger.v2?.logBaseUrl ||
      DEFAULT_LOG_BASE_URL
    );
  }

  private ensureMcsRunEnv() {
    return getConfigValue<string>(this.application, 'mcsRunEnv') || 'unknown';
  }

  private ensureMcsRunEnvType() {
    return getConfigValue<string>(this.application, 'mcsRunEnvType') || 'unknown';
  }

  private ensureVersion() {
    return getConfigValue<string>(this.application, 'version') || 'unknown';
  }
}
