import { UnknownError } from '@cian/peperrors/shared';

import { createSystemLogger } from './createSystemLogger.js';

import type { SystemLogger } from '../src/shared/Logger/index.js';

let logger: SystemLogger | undefined;
const defferedErrors: Error[] = [];

function log(error: Error) {
  if (logger) {
    logger.error(error);
  } else {
    defferedErrors.push(error);
  }
}

window.addEventListener('error', (event: ErrorEvent) => {
  const error = new UnknownError({
    message: event.message,
    domain: `system-logger.global.${event.error?.name || 'unknown'}`,
    details: {
      filename: event.filename,
      lineno: event.lineno,
      colno: event.colno,
    },
  });
  error.stack = event.error?.stack;

  log(error);
});

/**
 * Для того чтобы логгер создался уже с актуальными настройками
 */
document.addEventListener('DOMContentLoaded', () => {
  logger = createSystemLogger();

  let defferedError;

  while ((defferedError = defferedErrors.pop())) {
    logger.error(defferedError);
  }
});
