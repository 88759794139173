import { GenericError } from '@cian/peperrors/shared';

import { convertMetaToKVCollection } from './ExceptionData.js';

import type { IBaseAppELKErrorLog, IBaseAppELKMessageLog } from './IELKAppLog.js';
import type { IErrorLog, IMessageLog } from './ILog.js';

export function createELKErrorLog(params: IErrorLog): IBaseAppELKErrorLog {
  const { level, message: error, meta = {} } = params;

  const date = new Date().toISOString();
  const exceptionType = error instanceof GenericError ? error.domain : `system-logger.unknown-domain.${error.name}`;
  const exceptionData = error instanceof GenericError ? Object.assign(meta, error.details) : meta;
  const exception = error.stack || `${error.name || error.constructor.name} ${error.message}`;

  return {
    date,
    level,
    message_type: 'app',
    exception_message: error.message,
    exception,
    exception_type: exceptionType,
    exception_data: convertMetaToKVCollection(exceptionData),
  };
}

export function createELKMessageLog(params: IMessageLog): IBaseAppELKMessageLog {
  const { level, message, meta = {} } = params;
  const date = new Date().toISOString();

  return {
    date,
    level,
    message,
    message_type: 'app',
    exception_data: convertMetaToKVCollection(meta),
  };
}
