import ansiColors, { type StyleFunction } from 'ansi-colors';

import { type IFormatter } from './IFormatter.js';
import { type IBaseAppELKErrorLog, type IBaseAppELKMessageLog } from '../Log/IELKAppLog.js';
import { type TLogLevel } from '../Log/ILog.js';

// eslint-disable-next-line import/no-named-as-default-member
const { red, yellow, blue } = ansiColors;

export class PrettyFormatter implements IFormatter<IBaseAppELKErrorLog | IBaseAppELKMessageLog, string> {
  public format(log: IBaseAppELKErrorLog | IBaseAppELKMessageLog) {
    const preffix = `[${log.date}][${log.level}]`;

    const rowFormat = createRowFormatter(preffix, log.level);

    if ('exception' in log) {
      return this.errorLog(log, rowFormat);
    }

    return this.messageLog(log, rowFormat);
  }

  private errorLog(log: IBaseAppELKErrorLog, rowFormat: TRowFormatter) {
    const { exception, exception_message, exception_type, exception_data } = log;

    let str = rowFormat('message', exception_message);
    str += rowFormat('domain', exception_type);
    str += rowFormat('exception', exception);
    str += rowFormat('details', `\n${JSON.stringify(exception_data, null, 2)}`);

    return str;
  }

  private messageLog(log: IBaseAppELKMessageLog, rowFormat: TRowFormatter) {
    const { message } = log;

    return rowFormat('message', message);
  }
}

type TRowFormatter = (key: string, value: unknown) => string;

function createRowFormatter(preffix: string, level: TLogLevel): TRowFormatter {
  let color: StyleFunction;

  switch (level) {
    case 'error':
    case 'fatal':
      color = red;

      break;
    case 'warning':
      color = yellow;
      break;
    default:
      color = blue;
  }

  // eslint-disable-next-line padding-line-between-statements
  return (key: string, value: unknown) => color(`${preffix}[${key}]: ${value}\n`);
}
