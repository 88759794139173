export function getConfigValue<T>(projectName: string, key: string): T | undefined {
  try {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    if (!(window as any)._cianConfig) {
      return;
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const config = (window as any)._cianConfig[projectName];

    if (!config) {
      return;
    }

    return searchInConfig(config, key) as T;
  } catch (error) {
    console.error(error);

    return;
  }
}

function searchInConfig(config: { key: string; value: unknown }[], target: string): unknown {
  return config.find(({ key }) => key === target)?.value;
}
