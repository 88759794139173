import {
  type IBaseAppELKErrorLog,
  type IBaseAppELKMessageLog,
  type IFormatter,
  type ITransport,
} from '../../shared/index.js';

export class HTTPTransport<AppLog extends IBaseAppELKErrorLog | IBaseAppELKMessageLog> implements ITransport<AppLog> {
  // eslint-disable-next-line @typescript-eslint/no-parameter-properties
  public constructor(private formatter: IFormatter<AppLog, string>, private logBaseUrl: string) {}

  public write(appLog: AppLog) {
    const log = this.formatter.format(appLog);

    return fetch(this.logBaseUrl, {
      headers: {
        'Content-Type': 'application/json',
      },
      method: 'POST',
      body: JSON.stringify({ error: log }),
    }).catch(err => console.error(err));
  }
}
