import {
  type IBaseAppELKErrorLog,
  type IBaseELKAppInfo,
  type IBaseAppELKMessageLog,
  type IFormatter,
} from '../../shared/index.js';
import { type IAppInfo } from '../AppInfo/IAppInfo.js';
import { type IELKClientInfo } from '../Log/IELKLog.js';

export class ELKFormatter implements IFormatter<IBaseAppELKErrorLog | IBaseAppELKMessageLog, string> {
  // eslint-disable-next-line @typescript-eslint/no-parameter-properties
  public constructor(private appInfo: IAppInfo) {}

  public format(log: IBaseAppELKErrorLog | IBaseAppELKMessageLog) {
    const elkAppInfo: IBaseELKAppInfo & IELKClientInfo = {
      runtime: 'browser',
      application: this.appInfo.application,
      mcs_run_env: this.appInfo.mcsRunEnv,
      version: this.appInfo.version,
      href: this.appInfo.href,
      available_screen_size: this.appInfo.availableScreenSize,
      screen_size: this.appInfo.screenSize,
    };

    return JSON.stringify(Object.assign(elkAppInfo, log));
  }
}
