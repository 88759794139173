import { type IFilter } from './IFilter.js';
import { type TError } from '../types.js';

export class ErrorFilter implements IFilter<TError> {
  protected readonly filters: RegExp[];

  public constructor(filters: RegExp[]) {
    this.filters = filters;
  }

  public approve(error: TError): boolean {
    let message: string | undefined;
    let stack: string | undefined;
    let name: string | undefined;

    if (typeof error === 'string') {
      message = error;
    } else {
      message = error.message;
      stack = error.stack;
      name = error.name;
    }

    return !this.filters.some(filter => {
      return (message && filter.test(message)) || (stack && filter.test(stack)) || (name && filter.test(name));
    });
  }
}
