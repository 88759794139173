import {
  type IBaseAppELKErrorLog,
  type IBaseAppELKMessageLog,
  type IFormatter,
  type ITransport,
} from '../../shared/index.js';

export class ConsoleTransport<AppLog extends IBaseAppELKErrorLog | IBaseAppELKMessageLog>
  implements ITransport<AppLog>
{
  // eslint-disable-next-line @typescript-eslint/no-parameter-properties
  public constructor(private formatter: IFormatter<AppLog, string>) {}

  public write(appLog: AppLog) {
    const log = this.formatter.format(appLog);

    switch (appLog.level) {
      case 'fatal':
      case 'error':
        console.error(log);

        return;
      case 'warning':
        console.warn(log);

        return;
      case 'info':
        console.info(log);

        return;
      case 'debug':
        console.debug(log);

        return;
      default:
        // eslint-disable-next-line no-console
        console.log(log);
    }
  }
}
